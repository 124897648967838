import * as React from 'react'
import * as d3 from 'd3'
import Layout from '../../components/layout'
import HorzBar from '../../charts/lines/horz-bar';

const VehicleMiles = () => {

  const gasPrices = [
    {
      "x": "Ala.",
      "y": 4.15,
      "hex": "#10b981"
    },
    {
      "x": "Alaska",
      "y": 4.73,
      "hex": "#10b981"
    },
    {
      "x": "Ariz.",
      "y": 4.6,
      "hex": "#10b981"
    },
    {
      "x": "Ark.",
      "y": 3.89,
      "hex": "#10b981"
    },
    {
      "x": "Calif.",
      "y": 5.74,
      "hex": "#10b981"
    },
    {
      "x": "Colo.",
      "y": 3.97,
      "hex": "#10b981"
    },
    {
      "x": "Conn.",
      "y": 4.47,
      "hex": "#10b981"
    },
    {
      "x": "Del.",
      "y": 4.21,
      "hex": "#10b981"
    },
    {
      "x": "D.C.",
      "y": 4.51,
      "hex": "#10b981"
    },
    {
      "x": "Fla.",
      "y": 4.34,
      "hex": "#10b981"
    },
    {
      "x": "Ga.",
      "y": 4.29,
      "hex": "#10b981"
    },
    {
      "x": "Hawaii",
      "y": 4.96,
      "hex": "#10b981"
    },
    {
      "x": "Idaho",
      "y": 4.36,
      "hex": "#10b981"
    },
    {
      "x": "Ill.",
      "y": 4.57,
      "hex": "#10b981"
    },
    {
      "x": "Ind.",
      "y": 4.24,
      "hex": "#10b981"
    },
    {
      "x": "Iowa",
      "y": 3.91,
      "hex": "#10b981"
    },
    {
      "x": "Kans.",
      "y": 3.82,
      "hex": "#10b981"
    },
    {
      "x": "Ky.",
      "y": 4.03,
      "hex": "#10b981"
    },
    {
      "x": "La.",
      "y": 4.15,
      "hex": "#10b981"
    },
    {
      "x": "Maine",
      "y": 4.26,
      "hex": "#10b981"
    },
    {
      "x": "Mass.",
      "y": 4.27,
      "hex": "#10b981"
    },
    {
      "x": "Md.",
      "y": 4.36,
      "hex": "#10b981"
    },
    {
      "x": "Mich.",
      "y": 4.24,
      "hex": "#10b981"
    },
    {
      "x": "Minn.",
      "y": 3.95,
      "hex": "#10b981"
    },
    {
      "x": "Miss.",
      "y": 4,
      "hex": "#10b981"
    },
    {
      "x": "Mo.",
      "y": 3.84,
      "hex": "#10b981"
    },
    {
      "x": "Mont.",
      "y": 4.02,
      "hex": "#10b981"
    },
    {
      "x": "Nebr.",
      "y": 3.89,
      "hex": "#10b981"
    },
    {
      "x": "Nev.",
      "y": 4.96,
      "hex": "#10b981"
    },
    {
      "x": "N.H.",
      "y": 4.28,
      "hex": "#10b981"
    },
    {
      "x": "N.J.",
      "y": 4.34,
      "hex": "#10b981"
    },
    {
      "x": "N. Mex.",
      "y": 4.18,
      "hex": "#10b981"
    },
    {
      "x": "N.Y.",
      "y": 4.45,
      "hex": "#10b981"
    },
    {
      "x": "N.C.",
      "y": 4.18,
      "hex": "#10b981"
    },
    {
      "x": "N. Dak.",
      "y": 3.9,
      "hex": "#10b981"
    },
    {
      "x": "Ohio",
      "y": 4.08,
      "hex": "#10b981"
    },
    {
      "x": "Okla.",
      "y": 3.85,
      "hex": "#10b981"
    },
    {
      "x": "Oreg.",
      "y": 4.74,
      "hex": "#10b981"
    },
    {
      "x": "Pa.",
      "y": 4.43,
      "hex": "#10b981"
    },
    {
      "x": "R.I.",
      "y": 4.35,
      "hex": "#10b981"
    },
    {
      "x": "S.C.",
      "y": 4.07,
      "hex": "#10b981"
    },
    {
      "x": "S. Dak.",
      "y": 3.97,
      "hex": "#10b981"
    },
    {
      "x": "Tenn.",
      "y": 4.11,
      "hex": "#10b981"
    },
    {
      "x": "Tex.",
      "y": 4,
      "hex": "#10b981"
    },
    {
      "x": "Utah",
      "y": 4.36,
      "hex": "#10b981"
    },
    {
      "x": "Vt.",
      "y": 4.3,
      "hex": "#10b981"
    },
    {
      "x": "Va.",
      "y": 4.23,
      "hex": "#10b981"
    },
    {
      "x": "Wash.",
      "y": 4.74,
      "hex": "#10b981"
    },
    {
      "x": "W. Va.",
      "y": 4.11,
      "hex": "#10b981"
    },
    {
      "x": "Wis.",
      "y": 4.02,
      "hex": "#10b981"
    },
    {
      "x": "Wyo.",
      "y": 4.03,
      "hex": "#10b981"
    },
    {
      "x": "U.S. Avg",
      "y": 4.32,
      "hex": "#334154"
    }
  ]

  const milesPerCapita = [
    {
      "x": "Ala.",
      "y": 14499,
      "hex": "#10b981"
    },
    {
      "x": "Alaska",
      "y": 7461,
      "hex": "#10b981"
    },
    {
      "x": "Ariz.",
      "y": 9238,
      "hex": "#10b981"
    },
    {
      "x": "Ark.",
      "y": 12124,
      "hex": "#10b981"
    },
    {
      "x": "Calif.",
      "y": 8737,
      "hex": "#10b981"
    },
    {
      "x": "Colo.",
      "y": 9512,
      "hex": "#10b981"
    },
    {
      "x": "Conn.",
      "y": 8815,
      "hex": "#10b981"
    },
    {
      "x": "D.C.",
      "y": 5347,
      "hex": "#10b981"
    },
    {
      "x": "Del.",
      "y": 10939,
      "hex": "#10b981"
    },
    {
      "x": "Fla.",
      "y": 10438,
      "hex": "#10b981"
    },
    {
      "x": "Ga.",
      "y": 11982,
      "hex": "#10b981"
    },
    {
      "x": "Hawaii",
      "y": 7546,
      "hex": "#10b981"
    },
    {
      "x": "Idaho",
      "y": 10072,
      "hex": "#10b981"
    },
    {
      "x": "Ill.",
      "y": 8452,
      "hex": "#10b981"
    },
    {
      "x": "Ind.",
      "y": 12279,
      "hex": "#10b981"
    },
    {
      "x": "Iowa",
      "y": 10658,
      "hex": "#10b981"
    },
    {
      "x": "Kans.",
      "y": 11090,
      "hex": "#10b981"
    },
    {
      "x": "Ky.",
      "y": 11059,
      "hex": "#10b981"
    },
    {
      "x": "La.",
      "y": 10539,
      "hex": "#10b981"
    },
    {
      "x": "Maine",
      "y": 11043,
      "hex": "#10b981"
    },
    {
      "x": "Mass.",
      "y": 9968,
      "hex": "#10b981"
    },
    {
      "x": "Md.",
      "y": 9134,
      "hex": "#10b981"
    },
    {
      "x": "Mich.",
      "y": 10203,
      "hex": "#10b981"
    },
    {
      "x": "Minn.",
      "y": 10774,
      "hex": "#10b981"
    },
    {
      "x": "Miss.",
      "y": 13678,
      "hex": "#10b981"
    },
    {
      "x": "Mo.",
      "y": 12431,
      "hex": "#10b981"
    },
    {
      "x": "Mont.",
      "y": 12014,
      "hex": "#10b981"
    },
    {
      "x": "N. Dak.",
      "y": 12871,
      "hex": "#10b981"
    },
    {
      "x": "N. Mex.",
      "y": 14189,
      "hex": "#10b981"
    },
    {
      "x": "N.C.",
      "y": 11606,
      "hex": "#10b981"
    },
    {
      "x": "N.H.",
      "y": 10143,
      "hex": "#10b981"
    },
    {
      "x": "N.J.",
      "y": 8723,
      "hex": "#10b981"
    },
    {
      "x": "N.Y.",
      "y": 6316,
      "hex": "#10b981"
    },
    {
      "x": "Nebr.",
      "y": 10962,
      "hex": "#10b981"
    },
    {
      "x": "Nev.",
      "y": 9289,
      "hex": "#10b981"
    },
    {
      "x": "Ohio",
      "y": 10257,
      "hex": "#10b981"
    },
    {
      "x": "Okla.",
      "y": 12566,
      "hex": "#10b981"
    },
    {
      "x": "Oreg.",
      "y": 8870,
      "hex": "#10b981"
    },
    {
      "x": "Pa.",
      "y": 7946,
      "hex": "#10b981"
    },
    {
      "x": "R.I.",
      "y": 7579,
      "hex": "#10b981"
    },
    {
      "x": "S. Dak.",
      "y": 11047,
      "hex": "#10b981"
    },
    {
      "x": "S.C.",
      "y": 11052,
      "hex": "#10b981"
    },
    {
      "x": "Tenn.",
      "y": 12261,
      "hex": "#10b981"
    },
    {
      "x": "Tex.",
      "y": 9648,
      "hex": "#10b981"
    },
    {
      "x": "U.S. Avg",
      "y": 9885,
      "hex": "#334154"
    },
    {
      "x": "Utah",
      "y": 10150,
      "hex": "#10b981"
    },
    {
      "x": "Va.",
      "y": 10074,
      "hex": "#10b981"
    },
    {
      "x": "Vt.",
      "y": 11892,
      "hex": "#10b981"
    },
    {
      "x": "W. Va.",
      "y": 10497,
      "hex": "#10b981"
    },
    {
      "x": "Wash.",
      "y": 8274,
      "hex": "#10b981"
    },
    {
      "x": "Wis.",
      "y": 11282,
      "hex": "#10b981"
    },
    {
      "x": "Wyo.",
      "y": 16901,
      "hex": "#10b981"
    }
  ]

  const sortedGasPrices = gasPrices.sort(function(a,b){
    return d3.descending(a.y, b.y)
  })

  const sortedMiles = milesPerCapita.sort(function(a,b){
    return d3.descending(a.y, b.y)
  })

  return (
    <Layout
      title={"Where Will High Gas Prices Hurt The Most?"}
      description={"A data visualization exploring the impact of higher gas prices in every state."}
    >
      
      <head>
        <meta name="robots" content="noindex" />
      </head>

      <section id="hero" className='mt-8 max-w-2xl mx-auto'>
        <div className='flex flex-col items-center pt-8 pb-4 px-4'>
          <h1 className='text-4xl lg:text-5xl'>Where will high gas prices hurt the most?</h1>
          <p className='mt-2 font-normal text-xl sm:text-2xl'>A data visualization exploring where Americans drive the most.</p>
        </div>
      </section>
      
      <HorzBar
        data={sortedGasPrices}
        height={1000}
        chartTitle={"Average gas prices by state"}
        titleStyling={"font-light text-xl"}
        containerStyling={"mx-auto py-12 w-11/12 sm:w-3/5"}
        yPadding={0.1}
        xMax={5.74}
        xFormat={"$.2f"}
        marginLeft={50}
        marginRight={50}
        dataLabels={true}
      />

      <HorzBar
        data={sortedMiles}
        height={1000}
        chartTitle={"Vehicle-miles-traveled (VMTs) per capita per year"}
        titleStyling={"font-light text-xl"}
        containerStyling={"mx-auto py-12 w-11/12 sm:w-3/5"}
        yPadding={0.1}
        xMax={17000}
        xFormat={".2~s"}
        marginLeft={50}
        marginRight={50}
        dataLabels={true}
      />

    </Layout>
  )
}

export default VehicleMiles