import React, { useEffect, useRef } from 'react'
import useChartDimensions from '../../hooks/useChartDimensions'
import * as d3 from 'd3'

const HorzBar = ({
  data,
  height,
  chartTitle,
  titleStyling,
  barFill,
  containerStyling,
  xMax,
  xFormat,
  xTicks,
  xAxisFontSize,
  yAxisFontSize,
  yPadding,
  marginLeft,
  marginRight,
  marginTop,
  dataLabels
}) => {

  const wrapperRef = useRef()
  const xRef = useRef()
  const yRef = useRef()
  const dimensions = useChartDimensions(wrapperRef)

  const margin = {
    left: marginLeft || 50,
    right: marginRight || 5,
    top: marginTop || 30,
  }

  const xScale = d3.scaleLinear()
    .domain([0, xMax || d3.max(data, d => d.y)])
    .range([0, dimensions.width - margin.left - margin.right])
  
  const yScale = d3.scaleBand()
    .domain(data.map(d => d.x))
    .range([0, dimensions.height - margin.top])
    .padding(yPadding)

  const xAxis = d3.axisTop(xScale)
    .ticks(xTicks || dimensions.width / 100)
    .tickFormat(d3.format(xFormat))

  const yAxis = d3.axisLeft(yScale)


  useEffect(() => {

    d3.select(xRef.current)
      .attr("transform", `translate(${margin.left}, 0)`)
      .attr("font-size", xAxisFontSize || 12)
      .join("g")
      .call(xAxis)

    d3.select(yRef.current)
      .attr("transform", `translate(${margin.left}, 0)`)
      .attr("font-size", yAxisFontSize || 12)
      .join("g")
      .call(yAxis)

  }, [dimensions])


  return (
    <div id="container-div" className={containerStyling}>
    <div style={{paddingLeft: margin.left, paddingBottom: 16}}>
      <p className={titleStyling}>{chartTitle}</p>
    </div>
    <div ref={wrapperRef}>
      <svg 
        className="w-full overflow-visible"
        id={"svg-wrapper"}
        height={height}
      >
        <g id="axis-group">
          <g id="x-axis" ref={xRef}></g>
          <g id="y-axis" ref={yRef}></g>
        </g>
        <g id="bar-group">
          {data.map((d, i) => 
            <g key={i}>
            <rect  
              x={0 + margin.left}
              y={yScale(d.x)}
              width={xScale(d.y)}
              height={yScale.bandwidth()}
              fill={
              barFill ? (barFill) : (
                d.hex ? (d.hex) : ("#10b981")
              )
              }
            />
            {
              dataLabels ?
              <text
                className="text-xs"
                fill={"#4b5563"}
                alignmentBaseline={"middle"}
                x={4 + margin.left + xScale(d.y)}
                y={yScale(d.x) + (yScale.bandwidth())/2}
              >
                {d3.format(xFormat)(d.y)}
              </text>
              : 
              <g />
            }
            </g>
          )}
        </g>
      </svg>
    </div>
    </div>
  )

}

export default HorzBar